.article-content > * {
  margin-bottom: 20px;
}

.article-content > h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: bold;
  padding: 0 20px;
}

.article-content > h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  padding: 0 20px;
}

.article-content > h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: bold;
  padding: 0 20px;
}

.article-content > a {
  color: #3b82f6;
  text-decoration: underline;
}

.article-content > p {
  padding: 0 20px;
}

.article-content > figure > img {
  width: 100%;
}

.article-content ul {
  list-style-type: square;
  list-style-position: inside;
  padding: 0px 20px;
}

.article-content ol {
  list-style-type: decimal;
  list-style-position: inside;
  padding: 0px 20px;
}

.article-content blockquote{
  margin:20px;
  padding: 12px 12px 24px 24px;
  border-left:4px solid #60a6fa ;
  line-height:1.6;
  position: relative;
  background:#f9f9f9;
  font-style:italic;
}

/* .article-content blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#ccc;
  font-size:3em;
  position: absolute;
  left: 8px;
  top:-8px;
} */

.article-content blockquote::after{
  content: '';
}

.article-content cite{
  display:block;
  color:#333333;
  font-style: italic;
  margin-top: 1em;
  font-size: 14px
}

.article-content .wp-block-buttons{
  display: flex;
  justify-content: center;
  padding: 0 20px;
}

.article-content .wp-block-button{
  width: fit-content;
  padding: 8px 12px;
  background-color: #3b82f6;
  color: white;
}

table {
  width: calc(100% - 40px);
  margin: 0px 20px;
  border-collapse: collapse;
}

th {
  border: 1px #b4d3e6 solid;
  background: #f3f8fa;
  padding: 12px 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #333;
}

td {
  background-color: #fff;
  border: 1px #b4d3e6 solid;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #666;
}